import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import IosPricetag from 'react-ionicons/lib/IosPricetag'

const renderPostTags = (tags) => {
  if (tags) {
    return <ul className="tags">
      {
        tags.map((tag, i) => (
          <li key={i}>
            <Link to={`/tags/${tag}`}><IosPricetag /> {tag}</Link>
          </li>
        ))
      }
    </ul>
  }
}

const PostPreview = (props) => {
  const { frontmatter } = props.data
  // console.log(frontmatter)  
  return (
    <div className="post-preview">
      <div className="post-details">
        <Link to={frontmatter.path}>
          <h3>{frontmatter.title}</h3>
        </Link>

        { renderPostTags(frontmatter.tags) }
      </div>

      <Link to={frontmatter.path}>
        <Img sizes={frontmatter.featuredImage.childImageSharp.sizes} />
      </Link>
   </div>
  )
}

export default PostPreview
