import React from 'react'
import Link from 'gatsby-link'
import IosPricetag from 'react-ionicons/lib/IosPricetag'
import PostPreview from '../components/PostPreview'
import Layout from '../components/Layout'

export default function Tags({ pageContext }) {
  const { posts, post, tag } = pageContext

  if (tag) {
    return (
      <Layout>
        <div>
          <Link
            to="/projects/"
            className="back-link">
              <span>⬅</span> Back to projects
          </Link>

          <h1>{post.length} post{post.length === 1 ? '' : 's'} tagged with "{tag}"</h1>
          {post.map((post, id) => {
            // console.log(post)
            return <PostPreview data={post} key={id} />
          })}
        </div>
      </Layout>
     )
   }

   return (
    <Layout>
     <div className="tags-page">
       <Link
         to="/projects/"
         className="back-link">
           <span>⬅</span> Back to projects
       </Link>

       <h1>Tags</h1>
       <ul className="tags tags-lg">
         {Object.keys(posts).map(tagName => {
          //  const tags = posts[tagName]
           return (
             <li key={tagName}>
               <Link to={`/tags/${tagName}`}>
                 <IosPricetag icon="ios-pricetags" /> {tagName}
               </Link>
             </li>
           )
         })}
       </ul>
     </div>
    </Layout>
   )
}
